<!--
  v-dialog / persistent : 밖에화면 눌렀을때 안꺼짐
  부모 컴포넌트에서 dialog 컨트롤
  :value="dialog"
  @input="$emit('update:dialog', false)"
-->
<template>
  <v-row justify="space-around">
    <v-dialog
      scrollable
      no-click-animation
      :fullscreen="fullscreen"
      :width="maxWidth"
      :max-width="maxWidth"
      :value="showDialog"
      @input="CLOSE_DIALOG"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6 font-weight-bold">{{ headline }}</span>
          <v-spacer v-if="showTitleCloseBtn"></v-spacer>
          <v-tooltip v-if="dialogType == 'fileView'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="goViewLink">
                <v-icon>
                  mdi-dock-window
                </v-icon>
              </v-btn>
            </template>
            <span v-text="$t('drive.14')"></span>
          </v-tooltip>
          <v-divider
            v-if="dialogType == 'fileView'"
            class="mx-1"
            vertical
            inset
          ></v-divider>
          <v-btn v-if="showTitleCloseBtn" icon @click="CLOSE_DIALOG">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <DialogContent
          ref="dialogContent"
          :confirm.sync="confirm"
          :second.sync="second"
          :confirmDisabled.sync="confirmDisabled"
          :secondDisabled.sync="secondDisabled"
        />
        <v-card-actions v-if="dialogType != 'fileView'" class="py-4">
          <v-btn
            v-if="dialogType === 'move' && !secondDisabled"
            text
            link
            @click="addFolder"
            v-text="$t('drive.15')"
          ></v-btn>
          <v-spacer></v-spacer>
          <!-- cancel 버튼 -->
          <v-btn text outlined @click="CLOSE_DIALOG">
            {{ btnTitles.cancel }}
          </v-btn>
          <!-- second 버튼 -->
          <v-btn
            v-show="showBtn.second"
            outlined
            color="accent"
            :disabled="secondDisabled"
            @click="second = true"
          >
            {{ btnTitles.second }}
          </v-btn>
          <!-- confirm 버튼 -->
          <v-btn
            v-show="showBtn.confirm"
            outlined
            color="accent"
            :disabled="confirmDisabled"
            @click="confirm = true"
          >
            {{ btnTitles.confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import DialogContent from "@/drive/views/components/dialog";

export default {
  components: {
    DialogContent
  },
  data: () => ({
    // confirm 버튼 클릭
    confirm: false,
    // confirm 버튼 Disabled 여부
    confirmDisabled: false,
    // second 버튼 클릭
    second: false,
    // second 버튼 Disabled 여부
    secondDisabled: false
  }),
  computed: {
    ...mapGetters("driveDialog", [
      "headline",
      "showTitleCloseBtn",
      "showDialog",
      "dialogType",
      "params",
      "btnTitles",
      "showBtn"
    ]),
    maxWidth() {
      switch (this.dialogType) {
        case "add":
          return 400;
        case "rename":
          return 400;
        case "move":
          return 400;
        default:
          return 400;
      }
    },
    fullscreen() {
      return this.dialogType == "fileView";
    }
  },
  watch: {
    dialogType() {
      this.confirm = false;
      this.confirmDisabled = false;
      this.second = false;
      this.secondDisabled = false;
    }
  },
  methods: {
    ...mapMutations("driveDialog", ["CLOSE_DIALOG"]),
    addFolder() {
      this.$refs.dialogContent.$refs.moveDialog.addFolder();
    },
    // 파일 새창으로 보기
    goViewLink() {
      window.open(`${this.params.file.webViewLink}`);
    }
  }
};
</script>

<style scoped>
.v-dialog__content >>> .v-dialog {
  overflow: hidden;
}
.v-dialog__content >>> .v-dialog > .v-card > .v-card__text {
  overflow-y: auto;
}
.moveDialogClass {
  border-bottom: #efefef 1px solid;
}
</style>
