<template>
  <Toolbar :showCheckbox="false" :items="items" />
</template>

<script>
import i18n from "@/_locales";
import Toolbar from "@/commons/views/toolbar/Toolbar";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Toolbar },

  data: () => ({}),
  props: ["selectedItems", "pageFileType"],
  computed: {
    ...mapGetters("auth", ["restrictedFunctions"]),
    items: function() {
      const { selectedItems, pageFileType } = this;
      const { length: selectedLength } = selectedItems;
      const disabled = selectedLength < 1;
      const soloDisabled = selectedLength !== 1;

      return [
        {
          title: i18n.t("drive.폴더추가"),
          disabled: false,
          showItem: pageFileType === "ROOT" || pageFileType === "DIRECTORY",
          click: () => this.$emit("addFolderDialog")
        },
        {
          title: i18n.t("drive.올리기"),
          disabled: false,
          showItem: pageFileType === "ROOT" || pageFileType === "DIRECTORY",
          click: () => this.$emit("openFileInput")
        },
        {
          title: i18n.t("drive.내려받기"),
          disabled,
          showItem: pageFileType !== "DELETED",
          click: () => this.$emit("downloadFileList")
        },
        {
          title: i18n.t("drive.휴지통비우기"),
          showItem: pageFileType === "DELETED",
          click: () => this.cleanTrash()
        },
        {
          title: i18n.t("common.삭제"),
          disabled,
          click: () =>
            pageFileType === "DELETED"
              ? this.$emit("deleteFile")
              : this.$emit("moveOnTrash")
        },
        {
          title: i18n.t("common.이동"),
          disabled,
          showItem: pageFileType !== "SHARE",
          click: () => this.$emit("openFolderTreeDialog")
        },
        {
          title: i18n.t("drive.이름바꾸기"),
          disabled: soloDisabled,
          showItem: pageFileType !== "DELETED",
          click: () => this.$emit("renameFileDialog")
        },
        {
          class: "cr-tutorial-drive-email",
          title: i18n.t("drive.27"),
          disabled: !this.isOnlyFiles(selectedItems),
          showItem: pageFileType === "ROOT" || pageFileType === "DIRECTORY",
          click: () => this.$emit("openMailWritePopup")
        },
        {
          class: "cr-tutorial-drive-share",
          title: i18n.t("drive.공유"),
          disabled:
            selectedLength !== 1
              ? true
              : selectedItems[0].fileType === "FILE"
              ? true
              : false,
          // 조직도 사용 제한에 따른 기능 비활성화
          showItem:
            pageFileType !== "DELETED" &&
            this.restrictedFunctions.isOrgChartRestricted == 0,
          click: () => this.$emit("openShareDialog", 0)
        }
        /* TODO 임시
        {
          title: "링크생성",
          disabled: true,
          // disabled: soloDisabled,
          showItem: pageFileType !== "DELETED",
          click: () => alert("준비중입니다.")
        }
        */
      ];
    }
  },
  methods: {
    ...mapActions("drive", ["cleanDeleted"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    //휴지통 비우기
    cleanTrash() {
      this.openConfirmDialog(
        i18n.t("drive.36"),
        i18n.t("drive.37"),
        async () => {
          this.disagree();
          const result = await this.cleanDeleted();

          if (!result) {
            this.openSnackbar({
              message: i18n.t("drive.38"),
              type: "ERROR"
            });
          } else {
            this.openSnackbar({
              message: i18n.t("drive.35"),
              type: "SUCCESS"
            });
          }
          this.$emit("update:selectedItems", []);
        }
      );
    },
    // 리스트보기, 바둑판보기 버튼변경
    changeBtn() {
      if (this.listIcon === "list") {
        this.listIcon = "module";
      } else {
        this.listIcon = "list";
      }
    },
    // 메일쓰기
    isOnlyFiles(items) {
      if (items.length == 0) return false;
      let result = true;

      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item.fileType != "FILE") return false;
      }
      return result;
    }
  }
};
</script>
