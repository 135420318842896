<template>
  <div
    class="cr-connect-contents px-4"
    style="position: relative; height: 100%; display:flex; align-items: center !important; justify-content: center;"
  >
    <div style="text-align:center;">
      <div class="text-h4 mb-10 login_logo mx-auto" />
      <div class="mb-7 text-h5 font-weight-bold" v-text="$t('drive.21')"></div>

      <div
        class="mb-7 text-subtitle-1 grey--text"
        v-text="$t('drive.22')"
      ></div>
      <v-btn
        style="text-transform: none"
        outlined
        text
        block
        :ripple="false"
        height="50"
        @click="goAuthGoogle"
      >
        <v-icon color="grey" left v-text="'mdi-google'" />
        <span v-text="$t('drive.23')"></span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getExternalAuthUrl } from "@/commons/api/external/token.api";
export default {
  data: () => ({
    authUrl: ""
  }),
  async created() {
    const { data } = await getExternalAuthUrl("GOOGLE_DRIVE");
    this.authUrl = data;
  },

  methods: {
    goAuthGoogle() {
      const popupX = window.screen.width / 2 - 650 / 2;
      // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음
      const popupY = window.screen.height / 2 - 650 / 2;
      window.open(
        `${this.authUrl}`,
        "",
        `width=650,height=650,left=${popupX},top=${popupY},location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes`
      );
    }
  }
};
</script>

<style scoped>
.login_logo {
  width: 121px;
  height: 68px;
  background-image: url("../../../../../../commons/assets/logo-login.png");
}
</style>
