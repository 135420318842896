<template>
  <div class="cr-connect-wrapper">
    <div class="d-flex">
      <!-- 탭 -->
      <v-tabs :show-arrows="false" v-model="selectedTab" @change="tabChange">
        <v-tab style="display: none;">{{ $t("drive.검색") }}</v-tab>
        <v-tab>{{ $t("drive.folder_ROOT") }}</v-tab>
        <v-tab>{{ $t("drive.folder_SHARE") }}</v-tab>
        <div class="d-flex align-center cr-search-wrapper ml-4">
          <v-menu
            tile
            content-class="elevation-2"
            ref="detailSearchMenu"
            v-model="detailSearchMenu"
            :close-on-content-click="false"
            offset-y
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="pt-0"
                color="dark"
                hide-details
                autocomplete="off"
                :placeholder="$t('drive.검색')"
                prepend-icon="mdi-magnify"
                ref="searchInput"
                :append-icon="
                  detailSearchMenu ? 'mdi-menu-up' : 'mdi-menu-down'
                "
                v-model="searchIncludeWord"
                v-on:keyup.enter="searchDrive"
                @click:append="on.click"
              ></v-text-field>
            </template>
            <v-card tile elevation="0" class="pa-5" width="550">
              <!-- 파일 유형 -->
              <v-row class="ma-0 mb-2">
                <v-col class="pb-1 pb-sm-3" cols="12" sm="4">
                  <div
                    class="text-subtitle-2 grey--text text--darken-1"
                    v-text="$t('drive.파일 유형')"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="py-0">
                  <v-select
                    background-color="grey lighten-3"
                    dense
                    color="dark"
                    filled
                    solo
                    flat
                    hide-details
                    :items="fileTypeList"
                    v-model="selectFileType"
                  >
                    <template v-slot:selection="{ item }">
                      <div class="d-flex align-center">
                        <v-icon
                          v-if="item.value != 'all'"
                          class="mr-2"
                          :color="item.iconColor"
                        >
                          {{ item.icon }}
                        </v-icon>
                        <div>{{ item.text }}</div>
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center">
                        <v-icon class="mr-2" :color="item.iconColor">
                          {{ item.icon }}
                        </v-icon>
                        <div class="text-subtitle-2">{{ item.text }}</div>
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!-- 제목 -->
              <v-row class="ma-0 mb-2">
                <v-col class="pb-1 pb-sm-3" cols="12" sm="4">
                  <div
                    class="text-subtitle-2 grey--text text--darken-1"
                    v-text="$t('drive.제목')"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="py-0">
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    autocomplete="off"
                    ref="inputTitle"
                    background-color="grey lighten-3"
                    tabindex="1"
                    hide-details
                    :placeholder="$t('drive.75')"
                    v-model="searchTitle"
                    v-on:keyup.enter="searchDrive"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- 다음 단어 포함 -->
              <v-row class="ma-0 mb-2">
                <v-col class="pb-1 pb-sm-3" cols="12" sm="4">
                  <div
                    class="text-subtitle-2 grey--text text--darken-1"
                    v-text="$t('drive.76')"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="py-0">
                  <v-text-field
                    dense
                    filled
                    solo
                    flat
                    autocomplete="off"
                    ref="inputContents"
                    background-color="grey lighten-3"
                    tabindex="2"
                    hide-details
                    :placeholder="$t('drive.77')"
                    v-model="searchIncludeWord"
                    v-on:keyup.enter="searchDrive"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- 수정 날짜 -->
              <v-row class="ma-0 mb-2">
                <v-col class="pb-1 pb-sm-3" cols="12" sm="4">
                  <div
                    class="text-subtitle-2 grey--text text--darken-1"
                    v-text="$t('drive.78')"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="py-0">
                  <v-select
                    background-color="grey lighten-3"
                    dense
                    color="dark"
                    filled
                    solo
                    flat
                    hide-details
                    :items="modifedDateTypeList"
                    v-model="selectModifedDateType"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row
                v-show="selectModifedDateType == 'custom'"
                class="ma-0 mb-2"
              >
                <v-col class="pb-1 pb-sm-3" cols="12" sm="4">
                  <div
                    class="text-subtitle-2 grey--text text--darken-1"
                    v-text="$t('drive.기간')"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="py-0">
                  <v-menu
                    tile
                    content-class="elevation-2"
                    ref="modifedDateMenu"
                    v-model="modifedDateMenu"
                    :close-on-content-click="false"
                    offset-y
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        readonly
                        hide-details
                        background-color="grey lighten-3"
                        autocomplete="off"
                        v-bind="attrs"
                        v-on="on"
                        v-model="dateRangeText"
                      ></v-text-field>
                    </template>
                    <!-- v-model="startDate" -->
                    <v-date-picker
                      v-model="dates"
                      range
                      no-title
                      scrollable
                      :day-format="datePickerDayFormat"
                      color="primary"
                      :max="maxDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        v-text="$t('common.취소')"
                        @click="dateCancel"
                      >
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        v-text="$t('common.확인')"
                        @click="modifedDateMenu = false"
                      >
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="ma-0 px-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3" text link @click="resetSearchData">
                  {{ $t("drive.조건 초기화") }}
                </v-btn>
                <v-btn depressed color="primary" @click="searchDrive">
                  {{ $t("drive.검색") }}
                </v-btn>
              </v-row>
            </v-card>
          </v-menu>
        </div>
      </v-tabs>
    </div>
    <!-- 리스트 -->
    <GoogleDriveList v-if="getExternalDriveType == 'google'"></GoogleDriveList>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import i18n from "@/_locales";
import GoogleDriveList from "@/drive/views/components/main/external/list/GoogleDriveList";

export default {
  components: {
    GoogleDriveList
  },
  created() {
    this.selectTab(this.getTabId);

    const date = new Date();
    this.maxDate = date.toISOString();
  },

  data: () => ({
    tabs: [
      { id: "search", name: "검색" },
      { id: "my", name: "내 드라이브" },
      { id: "share", name: "공유 문서함" }
    ],
    selectedTab: 1,
    // 검색
    detailSearchMenu: false,
    modifedDateMenu: false,
    /** 검색폼 */
    selectFileType: "all",
    searchTitle: "",
    searchIncludeWord: "",
    selectModifedDateType: "all",
    dates: [],
    // 날짜
    maxDate: "",

    fileTypeList: [
      {
        text: i18n.t("drive.모든 파일"),
        value: "all",
        icon: "mdi-check-all",
        iconColor: "blue"
      },
      {
        text: i18n.t("drive.폴더"),
        value: "folder",
        icon: "mdi-folder",
        iconColor: ""
      },
      {
        text: i18n.t("drive.문서"),
        value: "document",
        icon: "mdi-text-box",
        iconColor: "blue"
      },
      {
        text: i18n.t("drive.이미지"),
        value: "image",
        icon: "mdi-image",
        iconColor: "red"
      },
      {
        text: i18n.t("drive.동영상"),
        value: "video",
        icon: "mdi-video-box",
        iconColor: "red"
      },
      {
        text: i18n.t("drive.PDF"),
        value: "pdf",
        icon: "mdi-file-pdf-box",
        iconColor: "red darken-2"
      },
      {
        text: i18n.t("drive.압축파일"),
        value: "archive",
        icon: "mdi-folder-zip",
        iconColor: "brown darken-4"
      },
      {
        text: i18n.t("drive.프레젠테이션"),
        value: "presentation",
        icon: "mdi-file-presentation-box",
        iconColor: "yellow darken-2"
      },
      {
        text: i18n.t("drive.스프레드시트"),
        value: "spreadsheet",
        icon: "mdi-google-spreadsheet",
        iconColor: "green"
      }
    ],
    modifedDateTypeList: [
      {
        text: i18n.t("drive.79"),
        value: "all"
      },
      {
        text: i18n.t("drive.오늘"),
        value: "today"
      },
      {
        text: i18n.t("drive.어제"),
        value: "yesterday"
      },
      {
        text: i18n.t("drive.80"),
        value: "week"
      },
      {
        text: i18n.t("drive.81"),
        value: "month"
      },
      {
        text: i18n.t("drive.82"),
        value: "three_month"
      },
      {
        text: i18n.t("drive.83"),
        value: "custom"
      }
    ]
  }),
  destroyed() {
    this.SET_INIT_EXTERNAL_DATA();
  },
  watch: {
    getTabId(next, prev) {
      if (prev == "search") this.resetSearchData();
      this.selectTab(next);
    }
  },
  computed: {
    ...mapGetters("driveRoute", ["getExternalDriveType", "getTabId"]),
    dateRangeText() {
      return this.dates.join(" ~ ");
    }
  },
  methods: {
    ...mapMutations("driveExternal", ["SET_INIT_EXTERNAL_DATA"]),
    /* common */
    selectTab(tabId) {
      const index = this.tabs.findIndex(t => t.id == tabId);
      if (index != -1) {
        this.selectedTab = index;
      }
    },
    /* datePicker*/
    datePickerDayFormat(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    dateCancel() {
      this.dates = [];
      this.modifedDateMenu = false;
    },
    /* Tab */
    tabChange(tabId) {
      this.routeExternalTab(this.tabs[tabId].id);
    },
    routeExternalTab(tabId) {
      this.$router.push({
        name: "drive_external_list_by_tab",
        params: {
          externalType: this.getExternalDriveType,
          tabId: tabId || "my"
        }
      });
    },
    resetSearchData() {
      this.selectFileType = "all";
      this.searchTitle = "";
      this.searchIncludeWord = "";
      this.selectModifedDateType = "all";
      this.dates = [];
    },
    /* search */
    searchDrive() {
      const {
        selectFileType,
        searchTitle,
        searchIncludeWord,
        selectModifedDateType,
        dates
      } = this;

      // 검색 수정날짜 범위 세팅
      let startDate = "";
      let endDate = "";
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      switch (selectModifedDateType) {
        // 사용자 지정
        case "custom": {
          if (!dates[0]) break;
          startDate = new Date(dates[0]).toISOString();
          if (!dates[1]) break;
          endDate = new Date(dates[1]).toISOString();
          break;
        }
        case "today": {
          startDate = today.toISOString();
          break;
        }
        case "yesterday": {
          today.setDate(today.getDate() - 1);
          startDate = today.toISOString();
          break;
        }
        case "week": {
          today.setDate(today.getDate() - 7);
          startDate = today.toISOString();
          break;
        }
        case "month": {
          today.setMonth(today.getMonth() - 1);
          startDate = today.toISOString();
          break;
        }
        case "three_month": {
          today.setMonth(today.getMonth() - 3);
          startDate = today.toISOString();
          break;
        }

        default:
          break;
      }

      this.detailSearchMenu = false;
      this.$router.push({
        name: "drive_external_list_by_search",
        params: {
          externalType: this.getExternalDriveType,
          // tabId: "search",
          actionObj: JSON.stringify({
            fileType: selectFileType,
            title: searchTitle,
            includeWord: searchIncludeWord,
            dateType: selectModifedDateType,
            startDate,
            endDate
          })
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-connect-wrapper ::v-deep {
  .v-tabs {
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }
  }
  .cr-search-wrapper {
    // height: 44px;
    .v-input {
      .v-input__slot {
        // padding: 0 16px;
        .v-text-field__slot input {
          font-size: 14px;
        }
      }
      .v-input__slot:after,
      .v-input__slot:before {
        bottom: -2px;
      }
    }
  }
}
</style>
