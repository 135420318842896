<template>
  <div :class="`cr-drive-main ${getShowSetting ? 'cr-setting' : ''}`">
    <ExternalMain v-if="mainShowType == 'EXTERNAL'" />
    <ShareList
      v-else-if="mainShowType == 'SHARE'"
      @openFileInput="openFileInput"
      @uploadFiles="uploadFiles"
    />
    <DriveList
      v-else
      :key="getDriveListInfo.folderId"
      @openFileInput="openFileInput"
      @uploadFiles="uploadFiles"
    />
    <DragDropOverlay />
    <FileUploader />
    <Dialog />
    <ConfigDrawer />
    <input
      multiple
      type="file"
      ref="fileInput"
      class="cr-file-input"
      @change="changeFileInput"
    />

    <DriveTutorial />
  </div>
</template>

<style scoped>
/* input tag */
.cr-file-input {
  display: none;
}
</style>
<style lang="scss" scoped>
.cr-drive-main {
  height: 100%;
  // 환경설정 drawer
  .cr-wrapper {
    position: relative;
    height: 100%;
  }

  &.cr-setting {
    padding-right: 320px;
  }
}
</style>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import DriveTutorial from "@/commons/views/tutorial/DriveTutorial";

import DriveList from "@/drive/views/components/main/general/DriveList";
import ShareList from "@/drive/views/components/main/share/ShareList";
import ExternalMain from "@/drive/views/components/main/external/ExternalDriveMain";
import DragDropOverlay from "@/drive/views/components/main/DragDropOverlay";
import FileUploader from "@/drive/views/components/main/FileUploader";
import Dialog from "@/drive/views/components/main/Dialog";
import ConfigDrawer from "@/drive/views/components/main/ConfigDrawer";

export default {
  components: {
    DriveTutorial,
    DriveList,
    ShareList,
    ExternalMain,
    FileUploader,
    DragDropOverlay,
    Dialog,
    ConfigDrawer
  },
  async created() {
    // 환경설정 닫기
    this.SET_SHOW_SETTING(false);
    await this.initDriveData();
  },
  async destroyed() {},
  computed: {
    ...mapGetters("driveRoute", ["getDriveListInfo"]),
    ...mapGetters("driveLayout", ["getShowSetting"]),
    mainShowType() {
      return this.$route.name.includes("drive_share") ||
        this.$route.name.includes("drive_shared")
        ? "SHARE"
        : this.$route.name.includes("drive_external")
        ? "EXTERNAL"
        : "LIST";
    }
  },

  methods: {
    ...mapMutations("driveLayout", ["SET_SHOW_SETTING"]),
    ...mapActions("driveRoute", ["initDriveData"]),
    ...mapActions("driveFile", ["setFiles"]),
    // 파일 선택창 띄우기
    openFileInput() {
      this.$refs.fileInput.click();
    },
    async changeFileInput(e) {
      const files = e.target.files;

      /* 유효성 검사 */
      if (!files) return;
      this.uploadFiles(files);
    },
    async uploadFiles(files) {
      // 유효성 검사 후 파일 세팅
      await this.setFiles({ files });
      this.$refs.fileInput.value = "";
    }
  }
};
</script>
