<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          required
          autofocus
          ref="newName"
          :label="$t('drive.새폴더명*')"
          :hint="$t('drive.1')"
          maxlength="80"
          v-model="name"
          v-on:keyup.enter="() => $emit('update:confirm', true)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    confirm(next) {
      if (next) {
        this.addFolderFunc();
      }
    }
  },
  data: () => ({
    name: ""
  }),
  computed: {
    ...mapGetters("drive", ["getRootFolder"]),
    ...mapGetters("driveDialog", ["params"])
  },
  methods: {
    ...mapMutations("driveDialog", ["CLOSE_DIALOG"]),
    ...mapActions("drive", ["addFolder"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("driveDialog", ["confirmCallback"]),

    // 폴더 추가 함수
    async addFolderFunc() {
      const { name } = this;
      const { parentFolder } = this.params;
      this.name = name.trim();

      if (!this.name) {
        this.openSnackbar({
          message: i18n.t("drive.2"),
          type: "VALIDATION"
        });
        this.$emit("update:confirm", false);
        return this.$refs.newName.focus();
      }

      // 추가
      if (parentFolder) {
        const result = await this.addFolder({
          name: this.name,
          parentId:
            parentFolder.fileType === "ROOT" ||
            parentFolder.fileType === "DIRECTORY"
              ? parentFolder.id
              : this.getRootFolder[0].id
        });

        if (result) {
          this.openSnackbar({
            message: i18n.t("drive.5"),
            type: "SUCCESS"
          });
          this.confirmCallback();
        } else {
          this.openSnackbar({
            message: i18n.t("drive.4"),
            type: "ERROR"
          });
        }
      }
      this.CLOSE_DIALOG();
    }
  }
};
</script>
