<template>
  <div>
    <!-- 툴바 -->
    <Toolbar
      :selectedItems.sync="selectedItems"
      @resetItems="resetItems"
      @mailWrite="mailWrite"
    />
    <v-divider></v-divider>
    <!-- 리스트 -->
    <div class="cr-container">
      <v-data-table
        hide-default-footer
        :item-class="itemClass"
        :loading="getLoading"
        :headers="getHeaders"
        :items="getDriveList"
        :items-per-page="100"
        @click:row="clickRow"
        @dblclick:row="dblClickRow"
        v-model="selectedItems"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-hover v-slot:default="{ hover }">
            <div class="cr-name-wrapper d-flex align-center">
              <div class="mr-2">
                <v-icon v-text="item.icon" :color="item.iconColor"></v-icon>
                <v-avatar
                  v-show="item.isShortcut"
                  class="cr-shortcut"
                  size="13"
                  color="white"
                >
                  <v-icon x-small>mdi-arrow-right-top</v-icon>
                </v-avatar>
              </div>
              <span class="cr-title">
                {{ item.name }}
              </span>
              <v-icon
                v-if="hover && item.fileType != 'folder' && !item.isShortcut"
                small
                v-bind:title="$t('drive.14')"
                @click.stop="e => goViewLink(e, item)"
              >
                mdi-dock-window
              </v-icon>
            </div>
          </v-hover>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Toolbar from "@/drive/views/components/main/external/list/toolbar/GoogleDriveToolbar";

// import { makeLinkContent } from "@/drive/api/drive.api";
export default {
  data: () => ({
    selectedItems: []
  }),
  components: {
    Toolbar
  },
  watch: {
    getTabId() {
      this.resetItems();
    },
    getDriveList() {
      this.resetItems();
    }
  },
  computed: {
    ...mapGetters("driveRoute", ["getExternalDriveType", "getTabId"]),
    ...mapGetters("driveExternal", ["getDriveList", "getHeaders", "getLoading"])
  },
  methods: {
    ...mapMutations("driveDialog", ["SET_DIALOG"]),
    ...mapActions("mailRoute", ["routeMailWrite"]),
    // 테이블의 row(tr)에 할당할 클래스 이름 설정
    itemClass() {
      return "item-row";
    },
    // select표시 초기화
    resetItems() {
      this.selectedItems = [];
    },
    // 클릭 이벤트 함수 => select표시 on/off
    clickRow(item, row) {
      row.select(!row.isSelected);
    },

    /**
     * 더블클릭 이벤트
     * 폴더 -> 하위 폴더 리스트 로드
     * 파일 -> 현재 창에서 파일 보기
     */
    async dblClickRow(e, row) {
      e.preventDefault();
      const { item } = row;
      // 폴더 제외
      if (item.fileType == "folder") {
        this.resetItems();
        return this.$router.push({
          name: "drive_external_list_by_folderId",
          params: {
            externalType: this.getExternalDriveType,
            tabId: this.getTabId,
            folderId: item.id
          }
        });
      }

      // 이미지의 경우 새창으로보기로 변경
      if (
        item.fileType == "docx" ||
        item.fileType == "sheet" ||
        item.fileType == "xlsx" ||
        item.fileType == "pptx" ||
        item.fileType == "presentation"
      )
        return this.goFileViewDialog(item);
      this.goViewLink(e, item);
    },
    // 파일 현재창으로 보기(다이얼로그)
    goFileViewDialog(item) {
      this.SET_DIALOG({
        headline: item.name,
        type: "fileView",
        params: {
          file: item
        },
        showTitleCloseBtn: true
      });
    },
    // 파일 새창으로 보기
    goViewLink(e, item) {
      e.preventDefault();
      window.open(`${item.webViewLink}`);
    },
    // 메일쓰기
    async mailWrite() {
      const items = this.selectedItems.map(item => {
        return {
          name: item.name,
          linkUrl: item.webViewLink
        };
      });

      const params = items.map(param => {
        return (
          encodeURIComponent(param.name) +
          "=" +
          encodeURIComponent(param.linkUrl)
        );
      });

      this.routeMailWrite({
        t: 9,
        cp: params
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/* 데이타 테이블 */
.cr-container {
  position: absolute;
  top: 86px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  .v-data-table ::v-deep {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    /* content body */
    .v-data-table__wrapper {
      height: calc(100% - 54px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    /* header */
    .v-data-table-header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .v-input--selection-controls__input {
        margin-right: 0px;
      }
      > tr > th > .helper {
        font-size: 18px;
      }
    }
    /* row style */
    .item-row > .text-start {
      max-width: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > .cr-name-wrapper {
        position: relative;
        .cr-title {
          cursor: pointer;
          margin-right: 2px;
        }
        .cr-shortcut {
          position: absolute;
          left: -2px;
          bottom: -2px;
        }
      }
    }
  }
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
</style>
