<!--
  v-dialog / persistent : 밖에화면 눌렀을때 안꺼짐
  부모 컴포넌트에서 dialog 컨트롤
  :value="dialog"
  @input="$emit('update:dialog', false)"
-->
<template>
  <div>
    <v-dialog persistent max-width="650px" :value="showDialog">
      <!-- @input="showDialog = false" -->
      <v-card>
        <v-card-title>
          <span class="text-h6 font-weight-bold">{{ headline }}</span>
          <v-spacer></v-spacer>
          <v-btn icon right :ripple="false" @click="clickMinimize">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn icon right :ripple="false" @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="py-2">
            <span style="color: #1976d2;">{{ `${getCompletedCount}개` }}</span>
            <span style="color: #333;">{{ ` / ${getTotalCount}개` }}</span>
            <span class="ml-2" style="fontSize: 13px;">
              {{
                `(${byteCalculation(getTotalLoadedSize)} / ${byteCalculation(
                  getTotalSize
                )})`
              }}
            </span>
            <span style="float: right;">
              {{
                getRemainingTime == $t("drive.0초")
                  ? $t("drive.전송완료")
                  : $t("drive.18", { value: getRemainingTime })
              }}
            </span>
          </div>
          <v-progress-linear rounded :value="getTotalProgress" height="30">
            <strong>{{ Math.ceil(getTotalProgress) }}%</strong>
          </v-progress-linear>
          <v-data-table
            dense
            fixed-header
            hide-default-footer
            item-key="key"
            class="pt-5"
            :headers="headers"
            :item-class="itemClass"
            :items="getGridItems"
            :items-per-page="10"
            :server-items-length="10"
          >
            <!-- 전송량 -->
            <template v-slot:[`item.size`]="{ item }">
              {{
                `${byteCalculation(item.loaded)} / ${byteCalculation(
                  item.size
                )}`
              }}
            </template>
            <!-- 전송 상태 -->
            <template v-slot:[`item.progress`]="{ item }">
              <v-progress-linear
                v-if="item.status == 1"
                rounded
                :value="item.progress"
                height="12"
              />
              <div v-else>
                {{
                  item.status == 0 ? $t("drive.대기중") : $t("drive.전송완료")
                }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :color="getSnackbarColor"
      right
      v-model="isShowSnackbar"
      timeout="-1"
    >
      <v-icon v-if="getCompletedCount == getTotalCount">mdi-check</v-icon>
      <v-progress-circular
        v-else
        class="mr-1"
        :rotate="-90"
        :size="20"
        :width="3"
        :value="getTotalProgress"
      ></v-progress-circular>
      <span style="cursor: pointer;" @click="clickMaximize">
        {{
          isUploadComplete == true
            ? $t("drive.19", {
                value: getCompletedCount
              })
            : $t("drive.20", {
                totalCount: getTotalCount,
                completedCount: getCompletedCount
              })
        }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss" scoped>
.v-dialog__content ::v-deep {
  .v-dialog {
    overflow: hidden;

    .v-card__text {
      overflow: auto;

      .v-data-table {
        .v-data-table__wrapper {
          height: 190px;
          border: thin solid rgba(0, 0, 0, 0.12);
          /* table style*/
          table {
            // border-bottom: thin solid rgba(0, 0, 0, 0.12);

            tbody {
              tr > td {
                border: none;
              }
              /* row style */
              .file-row > .text-start {
                max-width: 1px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  data: () => ({
    headline: i18n.t("drive.17"),
    // 확인 버튼 클릭
    confirm: false,
    headers: [
      { text: i18n.t("drive.파일명"), sortable: false, value: "name" },
      {
        text: i18n.t("drive.전송 상태"),
        sortable: false,
        width: 100,
        value: "progress"
      },
      {
        text: i18n.t("drive.전송량"),
        sortable: false,
        width: 200,
        value: "size"
      }
    ]
  }),
  watch: {},
  computed: {
    ...mapGetters("driveFile", [
      "isShowDialog",
      "isShowSnackbar",
      "getGridItems",
      "getTotalProgress",
      "getTotalCount",
      "getCompletedCount",
      "getTotalSize",
      "getTotalLoadedSize",
      "getRemainingTime",
      "isUploadComplete"
    ]),
    getSnackbarColor() {
      return this.getCompletedCount == this.getTotalCount
        ? "light-blue accent-4"
        : "blue-grey darken-3";
    },
    showDialog: {
      get() {
        return this.isShowDialog;
      },
      set() {
        return this.close();
      }
    }
  },
  methods: {
    ...mapActions("driveFile", ["close", "minimize", "maximize"]),
    itemClass() {
      return "file-row";
    },
    byteCalculation(size) {
      return byteCalculation(size);
    },
    // 최소화 클릭
    clickMinimize() {
      this.minimize();
    },
    clickMaximize() {
      this.maximize();
    }
  }
};
</script>
