<template>
  <v-card-text
    class="pb-0"
    :style="
      dialogType == 'fileView'
        ? 'padding-left: 0px; padding-right: 0px; overflow-y: hidden;'
        : ''
    "
  >
    <FolderAddDialog
      v-if="dialogType === 'add'"
      v-bind="$props"
      v-on="$listeners"
    ></FolderAddDialog>
    <FileRenameDialog
      v-else-if="dialogType === 'rename'"
      v-bind="$props"
      v-on="$listeners"
    ></FileRenameDialog>
    <MoveFileDialog
      v-else-if="dialogType === 'move'"
      ref="moveDialog"
      v-bind="$props"
      v-on="$listeners"
    ></MoveFileDialog>
    <FileViewDialog
      v-else-if="dialogType === 'fileView'"
      v-bind="$props"
      v-on="$listeners"
    ></FileViewDialog>
    <ShortcutFileAddDialog
      v-else-if="dialogType === 'shorcutSelect'"
      v-bind="$props"
      v-on="$listeners"
    ></ShortcutFileAddDialog>
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
import FolderAddDialog from "@/drive/views/components/dialog/FolderAddDialog";
import FileRenameDialog from "@/drive/views/components/dialog/FileRenameDialog";
import MoveFileDialog from "@/drive/views/components/dialog/MoveFileDialog";
import FileViewDialog from "@/drive/views/components/dialog/FileViewDialog";
import ShortcutFileAddDialog from "@/drive/views/components/dialog/ShortcutFileAddDialog";

export default {
  components: {
    FolderAddDialog,
    FileRenameDialog,
    MoveFileDialog,
    FileViewDialog,
    ShortcutFileAddDialog
  },
  props: {
    confirm: {
      default: false,
      type: Boolean
    },
    second: {
      default: false,
      type: Boolean
    },
    confirmDisabled: {
      default: false,
      type: Boolean
    },
    secondDisabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("driveDialog", ["dialogType"])
  }
};
</script>
