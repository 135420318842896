<template>
  <div class="cr-wrapper">
    <ExternalDriveConnect v-if="showType == 'connect'" />
    <ExternalDriveList v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ExternalDriveConnect from "./ExternalDriveConnect";
import ExternalDriveList from "./ExternalDriveList";

export default {
  data: () => ({}),
  components: {
    ExternalDriveConnect,
    ExternalDriveList
  },
  computed: {
    ...mapGetters("driveRoute", ["getExternalDriveType"]),
    showType() {
      return this.$route.name == "drive_external" ? "connect" : "list";
    }
  }
};
</script>
