<template>
  <Toolbar :showCheckbox="false" :items="items"></Toolbar>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapMutations } from "vuex";
import { convertGoogleFileExportKeyByMimeType } from "@/commons/utils/google/driveUtil";
import Toolbar from "@/commons/views/toolbar/Toolbar";
export default {
  components: {
    Toolbar
  },
  props: ["selectedItems"],
  computed: {
    downLoadDisabled() {
      const { selectedItems } = this;
      if (selectedItems.length == 0) return true;
      const { fileType } = selectedItems[0];
      return fileType == "folder";
    },
    items: function() {
      const { selectedItems } = this;
      const { length: selectedLength } = selectedItems;
      const disabled = selectedLength < 1;
      return [
        {
          title: i18n.t("drive.선택해제"),
          disabled,
          click: () => {
            this.$emit("resetItems");
          }
        },
        {
          title: i18n.t("drive.내려받기"),
          disabled: selectedLength != 1,
          click: () => {
            const item = selectedItems[0];
            if (!item.exportLinks) return window.open(`${item.webViewLink}`);

            const exportUrlKey = convertGoogleFileExportKeyByMimeType(
              item.mimeType
            );
            const downloadUrl = !exportUrlKey
              ? item.exportLinks[Object.keys(item.exportLinks)[0]]
              : item.exportLinks[exportUrlKey];

            window.open(`${downloadUrl}`);
          }
        },
        {
          title: i18n.t("drive.링크복사"),
          disabled: selectedLength != 1,
          click: () => {
            window.navigator.clipboard
              .writeText(selectedItems[0].webViewLink)
              .then(() => {
                this.openSnackbar({
                  type: "SUCCESS",
                  message: i18n.t("drive.24")
                });
              });
          }
        },
        {
          title: i18n.t("drive.25"),
          disabled,
          click: () => {
            this.SET_DIALOG({
              headline: i18n.t("drive.26"),
              type: "shorcutSelect",
              btnTitles: {
                confirm: i18n.t("common.추가")
              },
              params: {
                files: selectedItems
              },
              confirmCallbackFunc: () => {
                this.$emit("resetItems");
              }
            });
          }
        },
        {
          title: i18n.t("drive.27"),
          disabled,
          click: () => this.$emit("mailWrite")
        }
      ];
    }
  },
  methods: {
    ...mapMutations("driveDialog", ["SET_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"])
  }
};
</script>
