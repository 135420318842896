<!-- TODO 새폴더생성 추가하기 -->
<template>
  <v-treeview
    style="height: 400px;"
    dense
    open-all
    hoverable
    activatable
    transition
    return-object
    item-text="fileName"
    :active.sync="activeItems"
    :open.sync="openItems"
    :items="movableTree"
    :key="`${openAll}`"
  >
    <template v-slot:prepend="{ item, open }">
      <v-icon v-if="item.fileType == 'ROOT'" :color="getIconColor(item)"
        >mdi-folder-home</v-icon
      >
      <v-icon
        v-else
        v-text="open ? 'mdi-folder-open' : 'mdi-folder'"
        :color="item.folderColor"
      ></v-icon>
    </template>
    <template v-slot:label="{ item }">
      <!-- 폴더 이름 -->
      <span class="cr-tree-label-text">
        {{ item.fileName }}
      </span>
      <!-- 폴더이름 수정필드 -->
      <!-- <v-text-field
        autofocus
        :loading="editNameLoading"
        v-if="item.isNew"
        v-on:blur="(e) => editNewFolderName(e, item)"
        v-on:keyup.enter="(e) => editNewFolderName(e, item)"
        :label="item.fileName"
      ></v-text-field> -->
    </template>
  </v-treeview>
</template>
<script>
import i18n from "@/_locales";
import { mapActions } from "vuex";
export default {
  data: () => ({
    movableTree: [],
    openItems: [],
    openAll: false,
    editNameLoading: false
  }),
  props: ["selectItems"],
  created() {
    this.getMovableTree();
  },
  computed: {
    activeItems: {
      get() {
        return this.selectItems;
      },
      set(items) {
        this.$emit("update:selectItems", items);
      }
    }
  },
  methods: {
    ...mapActions("drive", [
      "getMovableFolders",
      "moveFiles",
      "copyFiles",
      "validationMove",
      "addNewFolder",
      "updateFileName"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    // 이동 가능한 폴더 TREE 기본값 세팅
    async getMovableTree() {
      const result = await this.getMovableFolders();
      if (result.length > 0) {
        this.movableTree = result;
        this.openAll = true;
      } else {
        this.openSnackbar({
          message: i18n.t("drive.7"),
          type: "ERROR"
        });
      }
    },
    // 아이콘에 active색 적용
    getIconColor(item) {
      const [activeItem = {}] = this.activeItems;
      const { id } = activeItem;

      return id == item.id ? "primary" : "";
    }
    // Tree에서 해당 폴더찾기
    // getFolderInTree(folders, fileId) {
    //   for (let i = 0; i < folders.length; i += 1) {
    //     const folder = folders[i];
    //     if (folder.id == fileId) return folder;

    //     if (folder.children) {
    //       const childrenResult = this.getFolderInTree(folder.children, fileId);
    //       if (childrenResult) return childrenResult;
    //     }
    //   }
    // },
  }
};
</script>
