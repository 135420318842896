<template>
  <Toolbar :showCheckbox="false" :items="items" />
</template>

<script>
import i18n from "@/_locales";
import Toolbar from "@/commons/views/toolbar/Toolbar";
import { mapGetters } from "vuex";
export default {
  components: {
    Toolbar
  },
  props: ["selectedItems"],
  computed: {
    ...mapGetters("driveShare", ["getPermission"]),
    items: function() {
      const { selectedItems } = this;
      const { length: selectedLength } = selectedItems;
      const disabled = selectedLength < 1;
      return [
        {
          title: i18n.t("drive.올리기"),
          showItem:
            this.$route.name.includes("shared") && this.getPermission == 3,
          click: () => this.$emit("openFileInput")
        },
        {
          title: i18n.t("drive.공유취소"),
          disabled,
          showItem: !this.$route.name.includes("shared"),
          click: () => this.$emit("shareCancelDialog")
        },
        {
          title: i18n.t("drive.내려받기"),
          disabled,
          click: () => this.$emit("downloadShareFile")
        }
      ];
    }
  }
};
</script>
