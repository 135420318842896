<template>
  <v-treeview
    style="height: 400px;"
    dense
    open-all
    hoverable
    activatable
    transition
    return-object
    item-text="fileName"
    :active.sync="activeItems"
    :open.sync="openItems"
    :items="movableTree"
    :key="`${openAll}`"
  >
    <template v-slot:prepend="{ item, open }">
      <v-icon v-if="item.fileType == 'ROOT'" :color="getIconColor(item)"
        >mdi-folder-home</v-icon
      >
      <v-icon
        v-else
        v-text="open ? 'mdi-folder-open' : 'mdi-folder'"
        :color="item.folderColor"
      ></v-icon>
    </template>
    <template v-slot:label="{ item }">
      <!-- 폴더 이름 -->
      <span class="cr-tree-label-text" v-if="!item.isNew">
        {{ item.fileName }}
      </span>
      <!-- 폴더이름 수정필드 -->
      <v-text-field
        autofocus
        :loading="editNameLoading"
        v-if="item.isNew"
        v-on:blur="e => editNewFolderName(e, item)"
        v-on:keyup.enter="e => editNewFolderName(e, item)"
        :label="item.fileName"
      ></v-text-field>
    </template>
  </v-treeview>
</template>
<script>
import i18n from "@/_locales";
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    },
    confirmDisabled: {
      default: false,
      type: Boolean
    },
    second: {
      default: false,
      type: Boolean
    },
    secondDisabled: {
      default: false,
      type: Boolean
    }
  },

  created() {
    this.getMovableTree();
    this.btnDisabled(true);
  },
  destroyed() {
    this.btnDisabled(false);
  },
  data: () => ({
    movableTree: [],
    activeItems: [],
    openItems: [],
    openAll: false,
    editNameLoading: false
  }),
  watch: {
    async activeItems(selectItem) {
      const { selectedItems } = this.params;

      // 선택한 파일이 없다면 다시 return
      if (selectItem.length < 1) {
        this.btnDisabled(true);
        return;
      }

      // 휴지통인경우는 유효성검사 하지 않는다.
      if (this.pageFileType !== "DELETED") {
        // 선택된 파일들(or 폴더) 중 folder만 유효성 검사하기위해 갖고온다.
        const movedItems = selectedItems.filter(
          ({ fileType }) => fileType == "DIRECTORY"
        );

        // 만약 선택한 파일들(or 폴더)에 폴더 있으면 유효성검사 진행 (이동할 폴더가 본인 or 자식폴더일 경우 이동x)
        if (movedItems.length > 0) {
          const result = await this.validationMove({
            movableTree: this.movableTree,
            selectItem,
            movedItems
          });
          if (!result) {
            this.openSnackbar({
              message: i18n.t("drive.6"),
              type: "ERROR"
            });
            this.activeItems = [];
            return;
          }
        }
      }
      // 파일만 선택했거나, 이동할 수 있는 폴더라면 active효과 발생
      this.btnDisabled(selectItem.length ? false : true);
    },
    confirm(next) {
      if (next) {
        this.moveFileFunc();
      }
    },
    second(next) {
      if (next) {
        this.copyFileFunc();
      }
    }
  },
  computed: {
    ...mapGetters("drive", ["getFolder"]),
    ...mapGetters("driveRoute", ["getDriveListInfo"]),
    ...mapGetters("driveDialog", ["params"]),
    pageFileType() {
      if (this.$route.name == "drive") return "ROOT";
      const folder = this.getFolder(this.getDriveListInfo.folderId);
      if (!folder) return;
      return folder.fileType;
    }
  },
  methods: {
    ...mapMutations("driveDialog", ["CLOSE_DIALOG"]),
    ...mapActions("drive", [
      "getMovableFolders",
      "moveFiles",
      "copyFiles",
      "validationMove",
      "addNewFolder",
      "updateFileName"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("driveDialog", ["confirmCallback"]),

    // 이동, 복사 버튼 disabled 바꾸는 함수
    btnDisabled(disabled) {
      this.$emit("update:confirmDisabled", disabled);
      this.$emit("update:secondDisabled", disabled);
    },

    // 이동 가능한 폴더 TREE 기본값 세팅
    async getMovableTree() {
      const result = await this.getMovableFolders();
      if (result.length > 0) {
        this.movableTree = result;
        this.openAll = true;
      } else {
        this.openSnackbar({
          message: i18n.t("drive.7"),
          type: "ERROR"
        });
      }
    },

    // 아이콘에 active색 적용
    getIconColor(item) {
      const [activeItem = {}] = this.activeItems;
      const { id } = activeItem;

      return id == item.id ? "primary" : "";
    },

    // Tree에서 해당 폴더찾기
    getFolderInTree(folders, fileId) {
      for (let i = 0; i < folders.length; i += 1) {
        const folder = folders[i];
        if (folder.id == fileId) return folder;

        if (folder.children) {
          const childrenResult = this.getFolderInTree(folder.children, fileId);
          if (childrenResult) return childrenResult;
        }
      }
    },

    // 새폴더 추가
    async addFolder() {
      const [{ id: activeId }] = this.activeItems;

      // 새폴더 생성
      const result = await this.addNewFolder({
        parentFolderId: activeId
      });

      result.isNew = true;
      delete result.children;

      // Tree에 생성한 폴더 추가
      const folder = this.getFolderInTree(this.movableTree, activeId);
      folder.childCount += 1;

      // 폴더에 children이 있었다면 push로 새폴더를 추가해준다.
      // 그렇지 않다면 children을 만들어준다.
      let folderChildren = folder.children;
      if (folderChildren) {
        folderChildren.push(result);
      } else {
        const children = [result];
        Vue.set(folder, "children", children);
      }

      // 새폴더 생성후 부모폴더를 open 해주고,
      const isOpen = this.openItems.filter(
        ({ id: openId }) => openId == activeId
      );
      if (isOpen.length < 1) {
        this.openItems = [...this.openItems, this.activeItems[0]];
      }

      // 새폴더를 active해준다.
      const newFolder = this.getFolderInTree(this.movableTree, result.id);
      this.activeItems = [newFolder];
    },
    // 새폴더 만들 시 이름변경 부분
    async editNewFolderName(e, item) {
      const value = e.target.value.trim();

      // 변경할 이름이 없거나, 전과 동일하면 원상태로 돌려준다.
      if (!value || item.fileName == value) {
        item.isNew = false;
        this.editNameLoading = false;
      } else if (!this.editNameLoading) {
        this.editNameLoading = true;
        const result = await this.updateFileName({
          fileName: value,
          fileId: item.id
        });

        if (result) {
          item.isNew = false;
          this.editNameLoading = false;
          item.fileName = value;
        }
      }
    },

    // 파일(or 폴더) 이동
    async moveFileFunc() {
      const [{ id }] = this.activeItems;
      const [{ parentId }] = this.params.selectedItems;

      const result = await this.moveFiles({
        selectedItems: this.params.selectedItems,
        targetFolderId: id,
        parentId
      });

      if (result) {
        this.openSnackbar({
          message: i18n.t("drive.8"),
          type: "SUCCESS"
        });
        this.confirmCallback();
      } else {
        this.openSnackbar({
          message: i18n.t("drive.7"),
          type: "ERROR"
        });
      }
      this.CLOSE_DIALOG();
    },

    // 파일(or 폴더) 복사
    async copyFileFunc() {
      const [{ id: activeId }] = this.activeItems;
      const [{ parentId }] = this.params.selectedItems;

      // 현재폴더에서는 복사할 수 없게 함. => TODO 이동, 복사 중복시 설정
      if (activeId == parentId) {
        this.openSnackbar({
          message: i18n.t("drive.9"),
          type: "ERROR"
        });
        this.$emit("update:second", false);
        return;
      }

      const result = await this.copyFiles({
        selectedItems: this.params.selectedItems,
        targetFolderId: activeId
      });

      if (result) {
        this.openSnackbar({
          message: i18n.t("drive.10"),
          type: "SUCCESS"
        });
        this.confirmCallback();
      } else {
        this.openSnackbar({
          message: i18n.t("drive.7"),
          type: "ERROR"
        });
      }
      this.CLOSE_DIALOG();
    }
  }
};
</script>
