<template>
  <iframe
    style="border: none; border: thin solid rgba(0, 0, 0, 0.12);"
    :src="fileSrc"
    :title="$t('drive.내용')"
    width="100%"
    height="100%"
  />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("driveDialog", ["params"]),
    fileSrc() {
      return this.params.file.webViewLink;
    }
  }
};
</script>
