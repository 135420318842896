<template>
  <div class="cr-connect-wrapper">
    <GoogleDriveConnect
      v-if="getExternalDriveType == 'google'"
    ></GoogleDriveConnect>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoogleDriveConnect from "@/drive/views/components/main/external/connect/GoogleDriveConnect";

export default {
  components: { GoogleDriveConnect },
  computed: {
    ...mapGetters("driveRoute", ["getExternalDriveType"])
  }
};
</script>

<style scoped>
.cr-connect-wrapper {
  position: relative;
  height: 100%;
}
</style>
