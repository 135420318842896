<template>
  <div class="cr-wrapper">
    <Toolbar
      :selectedItems.sync="selectedItems"
      @shareCancelDialog="shareCancelDialog"
      @downloadShareFile="downloadShareFile"
      @openFileInput="openFileInput"
    />
    <v-divider />
    <div class="cr-container">
      <v-data-table
        v-model="selectedItems"
        item-key="id"
        show-select
        hide-default-header
        hide-default-footer
        :headers="getShareHeaders"
        :items="getShareList"
        :items-per-page="getPageSize"
        :server-items-length="getTotalElements"
        :loading="getLoading"
        :options.sync="options"
        :item-class="itemClass"
        @page-count="pageCount = $event"
        @click:row="clickRow"
        @dblclick:row="dblClickRow"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header" ref="header">
            <tr>
              <th
                v-for="header in headers"
                :key="header.value"
                class="text-start"
                :class="getHeaderClass(header)"
                style="width: 1px; min-width: 1px;"
                :style="`width: ${header.width}`"
                @click="header.sortable && clickHeader(header)"
              >
                <v-checkbox
                  ref="toggleCheckbox"
                  v-if="header.value == 'data-table-select'"
                  hide-details
                  color="gray"
                  class="ma-0 pa-0"
                  :indeterminate="indeterminate"
                  :ripple="false"
                  @change="toggleCheckbox"
                  v-model="toggleCheck"
                />
                {{ header.text }}
                <v-icon
                  v-if="header.sortable"
                  class="helper v-data-table-header__icon"
                  v-text="'mdi-arrow-up'"
                />
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:[`item.fileName`]="{ item }">
          <div class="cr-title-wrapper">
            <v-icon
              class="mr-2"
              v-text="item.icon"
              :color="item.iconColor"
            ></v-icon>
            <v-avatar
              v-show="item.isShortcut"
              class="cr-shortcut"
              size="13"
              color="white"
            >
              <v-icon x-small>mdi-arrow-right-top</v-icon>
            </v-avatar>
            <span v-if="item.fileType === 'DIRECTORY'" class="cr-title">
              {{ item.fileName }}
            </span>
            <span v-else class="cr-title">
              {{
                !item.extension || item.isShortcut
                  ? item.fileName
                  : item.fileName + "." + item.extension
              }}
            </span>
          </div>
        </template>
        <template v-slot:footer>
          <!-- 
            페이징
            page : 현재페이지
            pageSize : 한페이지당 아이템 수
            blockSize : 페이지 한 블럭당 갯수
            totalElements : 아이템 총 갯수
            pageClick : 페이지 이동시 라우팅 이벤트
           -->
          <Pagination
            v-if="pageCount > 0"
            type="list"
            :page="page"
            :pageSize="getPageSize"
            :blockSize="getBlockSize"
            :totalElements="getTotalElements"
            @pageClick="pageClick"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* 데이타 테이블 */
.cr-container {
  position: absolute;
  top: 37px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  .v-data-table ::v-deep {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    /* content body */
    .v-data-table__wrapper {
      height: calc(100% - 54px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    /* header */
    .v-data-table-header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .v-input--selection-controls__input {
        margin-right: 0px;
      }
      > tr > th > .helper {
        font-size: 18px;
      }
    }
    /* row style */
    .item-row > .text-start {
      max-width: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > .cr-title-wrapper {
        position: relative;
        .cr-title {
          cursor: pointer;
        }
        .cr-shortcut {
          position: absolute;
          left: -2px;
          bottom: -2px;
        }
      }
    }
  }
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";
import storage from "@/commons/api/storage";
import Toolbar from "./Toolbar";
import Pagination from "@/commons/views/Pagination";

import { shareShowShortcut } from "@/drive/api/drive.api";

export default {
  created() {
    // url로 들어왔을때 page값 설정
    this.page = this.getSharePage;
  },
  mounted() {
    document.addEventListener("dragover", function(e) {
      e.preventDefault();
    });
    document.addEventListener("dragenter", this.dragenterEventHandler);
    document.addEventListener("dragleave", this.dragleaveEventHandler);
    document.addEventListener("drop", this.dropEventHandler);
  },
  destroyed() {
    document.removeEventListener("dragenter", this.dragenterEventHandler);
    document.removeEventListener("dragleave", this.dragleaveEventHandler);
    document.removeEventListener("drop", this.dropEventHandler);
    this.INIT_SHARE_DATA();
  },
  components: {
    Toolbar,
    Pagination
  },
  watch: {
    selectedItems(selected) {
      if (selected.length == 0) {
        this.toggleCheck = false;
        return;
      }
      if (selected.length == this.getShareList.length) {
        this.toggleCheck = true;
        return;
      }
    },
    getSharePage(page) {
      this.page = page;
    },
    isUploadComplete(isUploadComplete) {
      if (isUploadComplete) {
        const { folderId, page, actionObj } = this.getShareListInfo;
        this.getShareDriveList({
          folderId,
          page,
          pageSize: this.getPageSize,
          actionObj
        });
        this.minimize();
      }
    }
  },
  data() {
    return {
      counter: 0,
      selectedItems: [],
      page: 1,
      pageCount: 0,
      toggleCheck: false,
      options: {
        mustSort: true,
        sortBy: ["usedTimeMillis"],
        sortDesc: [true]
      }
    };
  },
  computed: {
    ...mapGetters("driveRoute", ["getShareListInfo", "getSharePage"]),
    ...mapGetters("driveConfig", ["getPageSize", "getBlockSize"]),
    ...mapGetters("driveFile", ["isUploadComplete"]),
    ...mapGetters("driveShare", [
      "getShareHeaders",
      "getShareList",
      "getTotalElements",
      "getLoading",
      "getPermission"
    ]),
    indeterminate: function() {
      const { length: selectedLength } = this.selectedItems;
      const { length: listLength } = this.getShareList;

      return selectedLength > 0 && selectedLength < listLength;
    }
  },
  methods: {
    ...mapMutations("driveShare", ["INIT_SHARE_DATA"]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("share", ["toCancelShare"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("driveShare", ["getShareDriveList"]),
    ...mapActions("driveFile", ["setFiles", "minimize"]),
    ...mapActions("drive", ["setDragDropOverlay"]),
    ...mapActions("auth", ["checkToken"]),
    // 테이블의 row(tr)에 할당할 클래스 이름 설정
    itemClass() {
      return "item-row";
    },

    /* 파일 드래그앤 드랍 */
    dragenterEventHandler(e) {
      e.preventDefault();
      if (!this.$route.name.includes("shared")) return;
      if (this.getPermission != 3) return;
      this.counter += 1;

      this.setDragDropOverlay({ show: true, zIndex: 10 });
    },
    dragleaveEventHandler(e) {
      e.preventDefault();
      if (!this.$route.name.includes("shared")) return;
      if (this.getPermission != 3) return;
      this.counter -= 1;

      if (this.counter === 0) {
        this.setDragDropOverlay({ show: false, zIndex: -1 });
      }
    },
    // 드랍
    dropEventHandler(e) {
      e.preventDefault();
      if (!this.$route.name.includes("shared")) return;
      if (this.getPermission != 3) return;
      this.counter = 0;
      this.setDragDropOverlay({ show: false, zIndex: -1 });
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles || droppedFiles.length == 0) return;

      this.$emit("uploadFiles", droppedFiles);
    },

    // 파일 선택창 띄우기
    openFileInput() {
      this.$emit("openFileInput");
    },

    getHeaderClass(header) {
      const { sortBy, sortDesc } = this.options;
      let cls = header.class ? header.class : "";

      if (header.sortable) {
        cls += " sortable";
        if (sortBy[0] == header.value) {
          cls += " active";
        }
        if (sortDesc[0]) {
          cls += " desc";
        } else {
          cls += " asc";
        }
      }
      return cls;
    },

    // 체크박스
    toggleCheckbox(checked) {
      if (checked) {
        this.selectedItems = this.getShareList;
      } else {
        this.selectedItems = [];
      }
    },

    // page선택시 함수(페이지 이동처리) -> 인자로 페이지 번호를 받는다.
    pageClick(page) {
      // 선택된 체크표시 초기화
      this.selectedItems = [];
      // routing시 전달되는 parameter가 있는 지 체크
      const { params } = this.$route;
      const { actionObj } = params;
      let { folderId } = this.getShareListInfo;
      if (folderId == -3) folderId = "share";

      // 페이지 정보 를 가지고 라우팅 진행
      let routeName;
      if (folderId == "share") {
        routeName = actionObj ? "drive_share_list_action" : "drive_share_list";
      } else {
        routeName = actionObj
          ? "drive_shared_list_action"
          : "drive_shared_list";
      }

      this.$router.push({
        name: routeName,
        params: { folderId, page, actionObj }
      });
    },

    // 헤더 옆 정렬 버튼
    clickHeader(header) {
      const { sortBy, sortDesc } = this.options;
      if (sortBy[0] == header.value) {
        this.options.sortDesc[0] = !sortDesc[0];
      } else {
        this.options.sortBy[0] = header.value;
        this.options.sortDesc[0] = false;
      }
      this.$forceUpdate();

      const { folderId: id, page } = this.getShareListInfo;
      let parsedAction = {
        sort: sortBy[0],
        dir: sortDesc[0] ? "desc" : "asc"
      };

      let shareId = id == -3 ? "share" : id;

      this.$router.push({
        name:
          shareId == "share"
            ? "drive_share_list_action"
            : "drive_shared_list_action",
        params: {
          folderId: shareId,
          page,
          actionObj: JSON.stringify(parsedAction)
        }
      });
    },
    // 클릭 이벤트 함수 => 체크표시 on/off
    clickRow(item, row) {
      row.select(!row.isSelected);
    },

    // 더블클릭 이벤트 함수 => 폴더일 경우 해당 폴더 열기
    //                     => 파일일 경우 해당 파일 다운로드
    /**
     * 리스트에서 row를 더블클릭event
     * 폴더 -> 폴더 이동
     * 파일 -> 파일 다운로드
     * 바로가기 파일 -> 바로가기 이동
     * */
    async dblClickRow(event, row) {
      if (row.item.fileType == "DIRECTORY") {
        this.$router.push({
          name: "drive_shared_list",
          params: { folderId: row.item.id, page: 1 }
        });
        this.selectedItems = [];
      } else if (row.item.fileType == "FILE" && !row.item.isShortcut) {
        await this.checkToken();
        const fileIds = [row.item.id];
        const token = storage.getAccessToken();
        window.open(
          `${process.env.VUE_APP_API_SERVER_URL}/api/drive/share/download?fileIds=${fileIds}&access_token=${token}`
        );
      } else if (row.item.isShortcut) {
        const { status, data } = await shareShowShortcut(row.item.id);

        if (status != 200) {
          return this.openSnackbar({
            message: i18n.t("drive.4"),
            type: "ERROR"
          });
        }

        if (data.result) {
          window.open(`${data.url}`);
        } else {
          let message = i18n.t("drive.4");
          let type = "ERROR";
          switch (data.message) {
            case "permission":
              message = i18n.t("drive.45");
              type = "VALIDATION";
              break;
            case "notShortcut":
              message = i18n.t("drive.29");
              type = "VALIDATION";
              break;
            default:
              break;
          }
          this.openSnackbar({
            message,
            type
          });
        }
      }
    },
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },

    // 공유 취소
    async shareCancelDialog() {
      let cancelList = [];
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        cancelList.push(this.selectedItems[i].id);
      }

      this.openConfirmDialog("", i18n.t("drive.46"), async () => {
        this.disagree();
        this.selectedItems = [];

        const result = await this.toCancelShare({
          cancelList,
          moduleType: "DRIVE"
        });

        const { actionObj, page } = this.$route.params;
        if (result) {
          this.getShareDriveList({
            folderId: -3,
            page,
            pageSize: this.getPageSize,
            actionObj
          });
        }
      });
    },

    // 공유파일 다운로드
    async downloadShareFile() {
      // 선택한 파일or폴더 유효성 검사
      if (this.selectedItems.length < 1) {
        this.openSnackbar({
          message: i18n.t("drive.39"),
          type: "ERROR"
        });
        return;
      }
      if (this.selectedItems.filter(item => !item.isShortcut).length == 0)
        return this.openSnackbar({
          message: i18n.t("drive.40")
        });
      if (this.selectedItems.filter(item => item.isShortcut).length > 0) {
        this.openConfirmDialog(
          i18n.t("drive.41"),
          i18n.t("drive.42"),
          async () => {
            await this.fileDownload();
          }
        );
      } else {
        await this.fileDownload();
      }
    },
    async fileDownload() {
      await this.checkToken();
      let fileIds = [];
      this.selectedItems.forEach(item => {
        fileIds.push(item.id);
      });

      const token = storage.getAccessToken();
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/api/drive/share/download?fileIds=${fileIds}&access_token=${token}`
      );
    }
  }
};
</script>
