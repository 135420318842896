<template>
  <v-overlay
    style="border: 5px solid #1867c0; text-align: center;
    bottom: 0px;
    right: 0px;
    left: -256px;
    top: -7px;"
    absolute
    opacity="0.75"
    :z-index="getOverlayInfo.zIndex"
    :value="getOverlayInfo.show"
  >
    <v-icon class="mb-5" style="font-size: 110px;">
      mdi-cloud-upload
    </v-icon>
    <div v-text="$t('drive.16')"></div>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("drive", ["getOverlayInfo"])
  }
};
</script>
