<template>
  <div>
    <DriveSelect :selectItems.sync="selectedItems"></DriveSelect>
  </div>
</template>

<script>
import i18n from "@/_locales";
import DriveSelect from "@/drive/views/components/dialog/sub/DriveSelect";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { convertGoogleFileIconByMimeType } from "@/commons/utils/google/driveUtil";
import { makeShortcut } from "@/drive/api/drive.api";
export default {
  created() {
    this.$emit("update:confirmDisabled", true);
  },
  components: {
    DriveSelect
  },
  data: () => ({
    selectedItems: []
  }),
  props: {
    confirm: {
      default: false,
      type: Boolean
    },
    confirmDisabled: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    // 선택 여부에 따른 confirm버튼 disabled
    isSelected(selected) {
      this.$emit("update:confirmDisabled", !selected);
    },
    async confirm(next) {
      if (next) {
        if (!this.isSelected) return this.$emit("update:confirm", false);

        await this.makeShortcut();
        this.confirmCallback();
        this.CLOSE_DIALOG();
      }
    }
  },
  computed: {
    ...mapGetters("driveDialog", ["params"]),
    // 선택했는지 여부
    isSelected() {
      return this.selectedItems.length > 0;
    }
  },
  methods: {
    ...mapMutations("driveDialog", ["CLOSE_DIALOG"]),
    ...mapActions("driveDialog", ["confirmCallback"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    async makeShortcut() {
      const [selectedItem = {}] = this.selectedItems;

      const shortcutFileList = this.params.files.map(item => {
        return {
          fileName: item.name,
          extension: item.isShortcut
            ? convertGoogleFileIconByMimeType(
                item.shortcutDetails.targetMimeType
              )
            : convertGoogleFileIconByMimeType(item.mimeType),
          shortcutUrl: item.webViewLink
        };
      });

      const params = {
        parentId: selectedItem.id,
        shortcutFileList
      };
      const { status } = await makeShortcut(params);
      this.openSnackbar({
        type: status == 200 ? "SUCCESS" : "ERROR",
        message: status == 200 ? i18n.t("drive.11") : i18n.t("drive.12")
      });
    }
  }
};
</script>
