<template>
  <div class="cr-wrapper">
    <Toolbar
      :pageFileType="pageFileType"
      :selectedItems.sync="selectedItems"
      @moveOnTrash="moveOnTrash"
      @deleteFile="deleteFile"
      @addFolderDialog="addFolderDialog"
      @renameFileDialog="renameFileDialog"
      @openFolderTreeDialog="openFolderTreeDialog"
      @downloadFileList="downloadFileList"
      @openShareDialog="openShareDialog"
      @openFileInput="openFileInput"
      @openMailWritePopup="openMailWritePopup"
    />
    <v-divider />
    <FastAccess
      v-if="pageFileType == 'ROOT' && showFastAccess"
      :showFastAccess.sync="showFastAccess"
      :onFastAccess.sync="onFastAccess"
    ></FastAccess>

    <div
      class="cr-container"
      :class="
        pageFileType != 'ROOT' || !showFastAccess
          ? ''
          : onFastAccess
          ? 'fastacceess-on'
          : 'fastacceess-off'
      "
    >
      <v-data-table
        v-model="selectedItems"
        item-key="id"
        show-select
        hide-default-header
        hide-default-footer
        :headers="getHeaders"
        :items="getList"
        :items-per-page="getPageSize"
        :server-items-length="getTotalElements"
        :options.sync="options"
        :item-class="itemClass"
        :loading="getLoading"
        @page-count="pageCount = $event"
        @click:row="clickRow"
        @dblclick:row="dblClickRow"
      >
        <!-- @contextmenu:row="openContextmenu" -->
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header" ref="header">
            <tr>
              <th
                v-for="header in headers"
                :key="header.value"
                class="text-start"
                :class="getHeaderClass(header)"
                style="width: 1px; min-width: 1px;"
                :style="`width: ${header.width}`"
                @click="header.sortable && clickHeader(header)"
              >
                <v-checkbox
                  ref="toggleCheckbox"
                  v-if="header.value == 'data-table-select'"
                  hide-details
                  color="gray"
                  class="ma-0 pa-0"
                  :indeterminate="indeterminate"
                  :ripple="false"
                  @change="toggleCheckbox"
                  v-model="toggleCheck"
                />
                {{ header.text }}
                <v-icon
                  v-if="header.sortable"
                  class="helper v-data-table-header__icon"
                  v-text="'mdi-arrow-up'"
                />
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:[`item.important`]="{ item }">
          <v-icon
            v-if="item.important == 1"
            color="yellow darken-1"
            @click="e => clickImportantStatus(e, item)"
          >
            mdi-star
          </v-icon>
          <v-icon v-else @click="e => clickImportantStatus(e, item)">
            mdi-star-outline
          </v-icon>
        </template>
        <template v-slot:[`item.fileName`]="{ item }">
          <div class="cr-title-wrapper">
            <v-icon
              class="mr-2"
              v-text="item.icon"
              :color="item.iconColor"
            ></v-icon>
            <v-avatar
              v-show="item.isShortcut"
              class="cr-shortcut"
              size="13"
              color="white"
            >
              <v-icon x-small>mdi-arrow-right-top</v-icon>
            </v-avatar>
            <span v-if="item.fileType === 'DIRECTORY'" class="cr-title">
              {{ item.fileName }}
            </span>
            <span v-else class="cr-title">
              {{
                !item.extension || item.isShortcut
                  ? item.fileName
                  : item.fileName + "." + item.extension
              }}
            </span>
          </div>
        </template>
        <template v-slot:footer>
          <!-- 
            페이징
            type : style (list, autocomplete)
            page : 현재페이지
            pageSize : 한페이지당 아이템 수
            blockSize : 페이지 한 블럭당 갯수
            totalElements : 아이템 총 갯수
            pageClick : 페이지 이동시 라우팅 이벤트
           -->
          <Pagination
            v-if="pageCount > 0"
            type="list"
            :page.sync="page"
            :pageSize="getPageSize"
            :blockSize="getBlockSize"
            :totalElements="getTotalElements"
            @pageClick="pageClick"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";
import storage from "@/commons/api/storage";
import Pagination from "@/commons/views/Pagination";
import Toolbar from "./Toolbar";
import FastAccess from "./FastAccess";

import { showShortcut } from "@/drive/api/drive.api";
export default {
  created() {
    // url로 들어왔을때 page값 설정
    this.page = this.getPage;
  },
  mounted() {
    document.addEventListener("dragover", function(e) {
      e.preventDefault();
    });
    document.addEventListener("dragenter", this.dragenterEventHandler);
    document.addEventListener("dragleave", this.dragleaveEventHandler);
    document.addEventListener("drop", this.dropEventHandler);
  },
  destroyed() {
    document.removeEventListener("dragenter", this.dragenterEventHandler);
    document.removeEventListener("dragleave", this.dragleaveEventHandler);
    document.removeEventListener("drop", this.dropEventHandler);
  },

  components: {
    Toolbar,
    FastAccess,
    Pagination
  },
  watch: {
    selectedItems(selected) {
      if (selected.length == 0) {
        this.toggleCheck = false;
        return;
      }
      if (selected.length == this.getList.length) {
        this.toggleCheck = true;
        return;
      }
    },
    getPage(page) {
      this.page = page;
    },
    getHeaders(header) {
      const [h] = header.filter(h => h.value == "deleteTimeMillis");
      // 휴지통
      if (h) {
        this.options = { ...this.options, sortBy: ["deleteTimeMillis"] };
      } else {
        this.options = { ...this.options, sortBy: ["usedTimeMillis"] };
      }
    },
    isUploadComplete(isUploadComplete) {
      if (isUploadComplete) {
        this.listRefresh();
        this.minimize();
      }
    }
  },
  data() {
    return {
      counter: 0,
      onFastAccess: true,
      showFastAccess: true,
      fab: false,
      selectedItems: [],
      page: 1,
      pageCount: 0,
      toggleCheck: false,
      options: {
        mustSort: true,
        sortBy: ["usedTimeMillis"],
        sortDesc: [true]
      }
    };
  },
  computed: {
    ...mapGetters("drive", [
      "getLoading",
      "getList",
      "getFolder",
      "getTotalElements",
      "getHeaders",
      "getRootFolder",
      "getDeletedFolder",
      "getOpenItems",
      "getActiveItems"
    ]),
    ...mapGetters("driveRoute", ["getDriveListInfo", "getPage"]),
    ...mapGetters("driveConfig", ["getPageSize", "getBlockSize"]),
    ...mapGetters("driveFile", ["isUploadComplete"]),
    pageFileType() {
      if (this.$route.name == "drive") return "ROOT";
      const folder = this.getFolder(this.getDriveListInfo.folderId);
      if (!folder) return;
      return folder.fileType;
    },
    indeterminate() {
      const { length: selectedLength } = this.selectedItems;
      const { length: listLength } = this.getList;

      return selectedLength > 0 && selectedLength < listLength;
    }
  },
  methods: {
    ...mapMutations("driveDialog", ["SET_DIALOG"]),
    ...mapActions("mailRoute", ["routeMailWrite"]),
    ...mapActions("drive", [
      "initDriveData",
      "updateSelectedItems",
      "getFile",
      "moveFolderByList",
      "toDeleted",
      "deleteFileOnTrash",
      "updateImportant",
      "updateShareFlag",
      "getFoldersByParentId",
      "listRefresh",
      "setDragDropOverlay"
    ]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("share", ["shareDialog"]),
    ...mapActions("linkShare", ["linkShareDialog"]),
    ...mapActions("driveFile", ["setFiles", "minimize"]),
    ...mapActions("auth", ["checkToken"]),

    /* 파일 드래그앤 드랍 */
    dragenterEventHandler(e) {
      e.preventDefault();
      if (this.pageFileType !== "ROOT" && this.pageFileType !== "DIRECTORY")
        return;
      this.counter += 1;
      this.setDragDropOverlay({ show: true, zIndex: 10 });
    },
    dragleaveEventHandler(e) {
      e.preventDefault();
      if (this.pageFileType !== "ROOT" && this.pageFileType !== "DIRECTORY")
        return;
      this.counter -= 1;

      if (this.counter === 0) {
        this.setDragDropOverlay({ show: false, zIndex: -1 });
      }
    },
    // 드랍
    dropEventHandler(e) {
      e.preventDefault();
      if (this.pageFileType !== "ROOT" && this.pageFileType !== "DIRECTORY")
        return;
      this.counter = 0;
      this.setDragDropOverlay({ show: false, zIndex: -1 });
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles || droppedFiles.length == 0) return;

      this.$emit("uploadFiles", droppedFiles);
    },

    // 파일 선택창 띄우기
    openFileInput() {
      this.$emit("openFileInput");
    },

    getHeaderClass(header) {
      const { sortBy, sortDesc } = this.options;
      let cls = header.class ? header.class : "";

      if (header.sortable) {
        cls += " sortable";
        if (sortBy[0] == header.value) {
          cls += " active";
        }
        if (sortDesc[0]) {
          cls += " desc";
        } else {
          cls += " asc";
        }
      }
      return cls;
    },

    // 헤더 정렬버튼 클릭시 table에 있는 option값 변경
    clickHeader(header) {
      const { sortBy, sortDesc } = this.options;
      if (sortBy[0] == header.value) {
        this.options.sortDesc[0] = !sortDesc[0];
      } else {
        this.options.sortBy[0] = header.value;
        this.options.sortDesc[0] = false;
      }
      this.$forceUpdate();
      // 여기수정
      const { folderId: id, page } = this.getDriveListInfo;
      let parsedAction = {
        sort: sortBy[0],
        dir: sortDesc[0] ? "desc" : "asc"
      };

      let folderId = id;
      switch (id) {
        case -1:
          folderId = "recent";
          break;
        case -2:
          folderId = "important";
          break;
        case -3:
          folderId = "share";
          break;
        case -4:
          folderId = "search";
          break;
      }

      this.$router.push({
        name: "drive_list_action",
        params: {
          folderId,
          page,
          actionObj: JSON.stringify(parsedAction)
        }
      });

      // 선택된 체크표시 초기화
      this.resetSelected();
    },

    toggleCheckbox(checked) {
      if (checked) {
        this.selectedItems = this.getList;
      } else {
        this.selectedItems = [];
      }
    },
    // page선택시 함수(페이지 이동처리) -> 인자로 페이지 번호를 받는다.
    pageClick(page) {
      // 선택된 체크표시 초기화
      this.resetSelected();

      // routing시 전달되는 parameter가 있는 지 체크
      const { params } = this.$route;

      let { folderId } = this.getDriveListInfo;
      if (folderId < 0) {
        switch (folderId) {
          case -1:
            folderId = "recent";
            break;
          case -2:
            folderId = "important";
            break;
          case -4:
            folderId = "search";
            break;
        }
      }
      const { actionObj } = params;
      // 페이지 정보 를 가지고 라우팅 진행
      this.$router.push({
        name: actionObj ? "drive_list_action" : "drive_list",
        params: { folderId, page, actionObj }
      });
    },

    // 선택항목 초기화
    resetSelected() {
      this.selectedItems = [];
    },

    // 테이블의 row(tr)에 할당할 클래스 이름 설정
    itemClass() {
      return "item-row";
    },

    // 리스트에서 row를 클릭했을때 함수
    clickRow(item, row) {
      row.select(!row.isSelected);
    },

    /**
     * 리스트에서 row를 더블클릭event
     * 폴더 -> 폴더 이동
     * 파일 -> 파일 다운로드
     * */
    async dblClickRow(event, row) {
      // 더블클릭 이벤트
      if (this.pageFileType == "ROOT" || this.pageFileType == "DIRECTORY") {
        if (row.item.fileType == "DIRECTORY") {
          // 폴더일 경우
          const folderId = row.item.id;

          if (row.item.childCount > 0) {
            await this.getFoldersByParentId({ parentId: folderId });
          }
          this.openFolder(event, row.item);
        } else if (row.item.fileType == "FILE" && !row.item.isShortcut) {
          await this.checkToken();
          // 파일일 경우
          const fileIds = [row.item.id];
          const token = storage.getAccessToken();
          window.open(
            `${process.env.VUE_APP_API_SERVER_URL}/api/drive/download?fileIds=${fileIds}&access_token=${token}`
          );
        } else if (row.item.isShortcut) {
          const { status, data } = await showShortcut(row.item.id);

          if (status != 200) {
            return this.openSnackbar({
              message: i18n.t("drive.4"),
              type: "ERROR"
            });
          }

          if (data.result) {
            window.open(`${data.url}`);
          } else {
            this.openSnackbar({
              message: i18n.t("drive.29"),
              type: "VALIDATION"
            });
          }
        }
      }
    },

    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },

    // 폴더 열기
    openFolder(e, item) {
      const { id: folderId } = item;

      this.$router.push({
        name: "drive_list",
        params: { folderId, page: 1 }
      });
    },

    /**
     * 별표 색 변경 (리스트)
     * important == 0 => 중요하지않음, 1 => 중요
     */
    clickImportantStatus(e, { id, important }) {
      e.stopPropagation();
      this.closePositioningMenu();
      const result = this.updateImportant({
        fileIds: [id],
        important: important ? 0 : 1
      });

      if (!result) {
        this.openSnackbar({
          message: i18n.t("drive.4"),
          type: "ERROR"
        });
      }
    },

    /**
     * 별표 색 변경 (컨텍스트메뉴)
     */
    changeImportant(e, important) {
      e.stopPropagation();
      this.closePositioningMenu();

      let fileIds = [];
      this.selectedItems.forEach(item => {
        fileIds.push(item.id);
      });

      const result = this.updateImportant({
        fileIds,
        important
      });

      if (!result) {
        this.openSnackbar({
          message: i18n.t("drive.4"),
          type: "ERROR"
        });
      }
    },

    // 폴더 추가 Dialog 열기
    addFolderDialog() {
      this.closePositioningMenu();
      this.SET_DIALOG({
        headline: i18n.t("drive.30"),
        type: "add",
        params: {
          parentFolder: this.getFolder(this.getDriveListInfo.folderId)
        },
        confirmCallbackFunc: () => {
          // 폴더 추가 후 해당 부모 폴더 열기
          this.resetSelected();
        }
      });
    },

    // (폴더 or 파일) 이름 수정
    renameFileDialog() {
      this.closePositioningMenu();
      this.SET_DIALOG({
        headline: i18n.t("common.이름_변경"),
        type: "rename",
        params: {
          file: this.selectedItems[0]
        },
        confirmCallbackFunc: () => {
          // 폴더 추가 후 해당 부모 폴더 열기
          this.resetSelected();
        }
      });
    },

    // 파일 이동
    openFolderTreeDialog() {
      this.closePositioningMenu();

      const onlyFiles =
        this.selectedItems.filter(({ fileType }) => fileType === "FILE")
          .length == this.selectedItems.length
          ? true
          : false;

      this.SET_DIALOG({
        headline: i18n.t("common.이동"),
        type: "move",
        params: {
          selectedItems: this.selectedItems
        },
        showSecondBtn: onlyFiles,
        btnTitles: {
          cancel: i18n.t("common.취소"),
          second: i18n.t("common.복사"),
          confirm: i18n.t("common.이동")
        },
        confirmCallbackFunc: () => {
          // 폴더 추가 후 해당 부모 폴더 열기
          this.resetSelected();
        }
      });
    },

    // 메일 쓰기 팝업 호출
    async openMailWritePopup() {
      const fileList = this.selectedItems.filter(item => !item.isShortcut);
      if (fileList.length == 0) {
        return this.openSnackbar({
          message: i18n.t("drive.32"),
          type: "VALIDATION"
        });
      }

      const fileIds = fileList.map(({ id }) => id);
      this.routeMailWrite({
        t: 8,
        is: fileIds
      });
    },

    // 공유추가시 아이콘 수정
    async addShareFlag(objectId) {
      await this.updateShareFlag({ id: objectId, shareFlag: 1 });
    },

    // 공유삭제시 아이콘 수정
    async deleteShareFlag(objectId) {
      await this.updateShareFlag({ id: objectId, shareFlag: 0 });
    },

    // 공유 다이얼로그 열기
    async openShareDialog(type) {
      const shareItem = {
        id: this.selectedItems[0].id,
        name: this.selectedItems[0].fileName
      };
      if (type == 0) {
        this.shareDialog({
          isShared: this.selectedItems[0].shareFlag == 1 ? true : false,
          moduleType: "DRIVE",
          shareItem,
          sendMessage: true,
          addCallback: this.addShareFlag,
          deleteCallback: this.deleteShareFlag
        });
        this.resetSelected();
      } else {
        shareItem["icon"] = this.selectedItems[0].icon;
        shareItem["iconColor"] =
          this.selectedItems[0].fileType == "DIRECTORY"
            ? "amber lighten-1"
            : this.selectedItems[0].iconColor;
        const result = await this.linkShareDialog({
          moduleType: "DRIVE",
          shareItem
        });

        if (result == 0) {
          this.openSnackbar({
            message: i18n.t("drive.4"),
            type: "ERROR"
          });
          return;
        }
        if (result == 1) {
          this.openSnackbar({
            message: i18n.t("drive.33"),
            type: "SUCCESS"
          });
          return;
        }
      }
    },

    // 폴더or파일 삭제(휴지통으로 이동) - 휴지통 이외 삭제
    moveOnTrash() {
      this.closePositioningMenu();

      let deleteList = [];
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        deleteList.push(this.selectedItems[i].id);
      }

      this.openConfirmDialog("", i18n.t("drive.34"), async () => {
        this.disagree();
        // 체크표시 업데이트
        this.resetSelected();

        const result = await this.toDeleted({
          fileIds: deleteList,
          parentId: this.getDriveListInfo.folderId
        });

        if (!result) {
          this.openSnackbar({
            message: i18n.t("drive.4"),
            type: "ERROR"
          });
        } else {
          this.openSnackbar({
            message: i18n.t("drive.35"),
            type: "SUCCESS"
          });
        }
      });
    },

    // 폴더or파일 삭제(영구삭제) - 휴지통에서 개별삭제
    deleteFile() {
      this.closePositioningMenu();

      let deleteList = [];
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        deleteList.push(this.selectedItems[i].id);
      }

      this.openConfirmDialog(
        i18n.t("drive.36"),
        i18n.t("drive.84"),
        async () => {
          this.disagree();
          // 체크표시 업데이트
          this.resetSelected();

          const result = await this.deleteFileOnTrash({ deleteList });
          if (!result) {
            this.openSnackbar({
              message: i18n.t("drive.38"),
              type: "ERROR"
            });
          } else {
            this.openSnackbar({
              message: i18n.t("drive.35"),
              type: "SUCCESS"
            });
          }
        }
      );
    },

    // 파일 다운로드
    async downloadFileList() {
      // 선택한 파일or폴더 유효성 검사
      if (this.selectedItems.length < 1) {
        this.openSnackbar({
          message: i18n.t("drive.39"),
          type: "ERROR"
        });
        return;
      }
      if (this.selectedItems.filter(item => !item.isShortcut).length == 0)
        return this.openSnackbar({
          message: i18n.t("drive.40")
        });

      if (this.selectedItems.filter(item => item.isShortcut).length > 0) {
        this.openConfirmDialog(
          i18n.t("drive.41"),
          i18n.t("drive.42"),
          async () => {
            await this.fileDownload();
          }
        );
      } else {
        await this.fileDownload();
      }
    },
    async fileDownload() {
      await this.checkToken();
      let fileIds = [];
      this.selectedItems.forEach(item => {
        fileIds.push(item.id);
      });

      const token = storage.getAccessToken();
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/api/drive/download?fileIds=${fileIds}&access_token=${token}`
      );
      this.resetSelected();
    }
  }
};
</script>

<style lang="scss" scoped>
/* 데이타 테이블 */
.cr-container {
  position: absolute;
  top: 37px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  .v-data-table ::v-deep {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    /* content body */
    .v-data-table__wrapper {
      height: calc(100% - 54px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    /* header */
    .v-data-table-header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .v-input--selection-controls__input {
        margin-right: 0px;
      }
      > tr > th > .helper {
        font-size: 18px;
      }
    }
    /* row style */
    .item-row > .text-start {
      max-width: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > .cr-title-wrapper {
        position: relative;
        .cr-title {
          cursor: pointer;
        }
        .cr-shortcut {
          position: absolute;
          left: -2px;
          bottom: -2px;
        }
      }
    }
  }
}
</style>

<style scoped>
#create .v-speed-dial {
  position: fixed;
  margin-bottom: 36px;
}
#create .v-btn--floating {
  position: relative;
}
/* fastAccess */
.v-main__wrap .fastacceess-on {
  top: 255px;
}
.v-main__wrap .fastacceess-off {
  top: 75px;
}
</style>
