var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-wrapper"},[_c('Toolbar',{attrs:{"pageFileType":_vm.pageFileType,"selectedItems":_vm.selectedItems},on:{"update:selectedItems":function($event){_vm.selectedItems=$event},"update:selected-items":function($event){_vm.selectedItems=$event},"moveOnTrash":_vm.moveOnTrash,"deleteFile":_vm.deleteFile,"addFolderDialog":_vm.addFolderDialog,"renameFileDialog":_vm.renameFileDialog,"openFolderTreeDialog":_vm.openFolderTreeDialog,"downloadFileList":_vm.downloadFileList,"openShareDialog":_vm.openShareDialog,"openFileInput":_vm.openFileInput,"openMailWritePopup":_vm.openMailWritePopup}}),_c('v-divider'),(_vm.pageFileType == 'ROOT' && _vm.showFastAccess)?_c('FastAccess',{attrs:{"showFastAccess":_vm.showFastAccess,"onFastAccess":_vm.onFastAccess},on:{"update:showFastAccess":function($event){_vm.showFastAccess=$event},"update:show-fast-access":function($event){_vm.showFastAccess=$event},"update:onFastAccess":function($event){_vm.onFastAccess=$event},"update:on-fast-access":function($event){_vm.onFastAccess=$event}}}):_vm._e(),_c('div',{staticClass:"cr-container",class:_vm.pageFileType != 'ROOT' || !_vm.showFastAccess
        ? ''
        : _vm.onFastAccess
        ? 'fastacceess-on'
        : 'fastacceess-off'},[_c('v-data-table',{attrs:{"item-key":"id","show-select":"","hide-default-header":"","hide-default-footer":"","headers":_vm.getHeaders,"items":_vm.getList,"items-per-page":_vm.getPageSize,"server-items-length":_vm.getTotalElements,"options":_vm.options,"item-class":_vm.itemClass,"loading":_vm.getLoading},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.clickRow,"dblclick:row":_vm.dblClickRow},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{ref:"header",staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,staticClass:"text-start",class:_vm.getHeaderClass(header),staticStyle:{"width":"1px","min-width":"1px"},style:(("width: " + (header.width))),on:{"click":function($event){header.sortable && _vm.clickHeader(header)}}},[(header.value == 'data-table-select')?_c('v-checkbox',{ref:"toggleCheckbox",refInFor:true,staticClass:"ma-0 pa-0",attrs:{"hide-details":"","color":"gray","indeterminate":_vm.indeterminate,"ripple":false},on:{"change":_vm.toggleCheckbox},model:{value:(_vm.toggleCheck),callback:function ($$v) {_vm.toggleCheck=$$v},expression:"toggleCheck"}}):_vm._e(),_vm._v(" "+_vm._s(header.text)+" "),(header.sortable)?_c('v-icon',{staticClass:"helper v-data-table-header__icon",domProps:{"textContent":_vm._s('mdi-arrow-up')}}):_vm._e()],1)}),0)])]}},{key:"item.important",fn:function(ref){
        var item = ref.item;
return [(item.important == 1)?_c('v-icon',{attrs:{"color":"yellow darken-1"},on:{"click":function (e) { return _vm.clickImportantStatus(e, item); }}},[_vm._v(" mdi-star ")]):_c('v-icon',{on:{"click":function (e) { return _vm.clickImportantStatus(e, item); }}},[_vm._v(" mdi-star-outline ")])]}},{key:"item.fileName",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"cr-title-wrapper"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.iconColor},domProps:{"textContent":_vm._s(item.icon)}}),_c('v-avatar',{directives:[{name:"show",rawName:"v-show",value:(item.isShortcut),expression:"item.isShortcut"}],staticClass:"cr-shortcut",attrs:{"size":"13","color":"white"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-arrow-right-top")])],1),(item.fileType === 'DIRECTORY')?_c('span',{staticClass:"cr-title"},[_vm._v(" "+_vm._s(item.fileName)+" ")]):_c('span',{staticClass:"cr-title"},[_vm._v(" "+_vm._s(!item.extension || item.isShortcut ? item.fileName : item.fileName + "." + item.extension)+" ")])],1)]}},{key:"footer",fn:function(){return [(_vm.pageCount > 0)?_c('Pagination',{attrs:{"type":"list","page":_vm.page,"pageSize":_vm.getPageSize,"blockSize":_vm.getBlockSize,"totalElements":_vm.getTotalElements},on:{"update:page":function($event){_vm.page=$event},"pageClick":_vm.pageClick}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }