<template>
  <div class="cr-fastAccess mb-2">
    <div style="display: inline-flex;">
      <v-subheader class="pr-1" v-text="$t('drive.43')"></v-subheader>
      <v-btn
        class="pt-4"
        icon
        small
        plain
        :ripple="false"
        @click="fastAccessOnOff"
      >
        <v-icon>
          {{ onFastAccess ? "mdi-menu-up" : "mdi-menu-down" }}
        </v-icon>
      </v-btn>
    </div>
    <v-expand-transition v-show="onFastAccess">
      <v-slide-group class="mx-1" v-model="file" show-arrows>
        <v-slide-item
          v-for="(item, i) in files"
          :key="i"
          v-slot="{ active, toggle }"
          active-class="cr-active"
        >
          <div
            class="px-2"
            :style="
              `max-width: 220px; display: inline-flex; width:${100 /
                files.length}%;`
            "
          >
            <v-card
              :ripple="false"
              :color="active ? '#e8f0fe' : ''"
              width="100%"
              outlined
              v-on:blur="e => fileActiveOff()"
              @click="toggle"
              @dblclick="downloadFileFastAccess(item)"
            >
              <div
                v-if="item.isShortcut"
                class="d-flex justify-center fileIcon pa-4"
              >
                <v-badge
                  bordered
                  bottom
                  left
                  icon="mdi-arrow-right-top"
                  overlap
                  offset-x="20"
                  offset-y="20"
                >
                  <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                </v-badge>
              </div>
              <div v-else class="d-flex justify-center fileIcon pa-4">
                <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-2">
                <div
                  class="subtitle-2 font-weight-medium"
                  style="text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden; "
                >
                  {{
                    !item.extension || item.isShortcut
                      ? item.fileName
                      : item.fileName + "." + item.extension
                  }}
                </div>
                <div class="caption text--secondary font-weight-medium">
                  {{ $t("drive.44", { value: item.typeContent }) }}
                </div>
              </div>
            </v-card>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-expand-transition>
  </div>
</template>

<style scoped>
.v-card > .fileIcon > i {
  font-size: 65px;
}
.v-card > .fileIcon > .v-badge > i {
  font-size: 65px;
}

.v-card >>> .v-card__subtitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cr-active > .v-card {
  border-color: skyblue !important;
}
.v-card--link:before {
  background: #fff;
}
</style>
<script>
import i18n from "@/_locales";
import { mapActions } from "vuex";
import storage from "@/commons/api/storage";

import { showShortcut } from "@/drive/api/drive.api";

export default {
  async created() {
    const files = await this.getFastAccess();
    if (files) {
      if (files.length > 0) {
        this.files = files;
      } else {
        this.$emit("update:showFastAccess", false);
      }
    } else {
      this.openSnackbar({
        message: i18n.t("drive.7"),
        type: "ERROR"
      });
    }
  },
  props: ["showFastAccess", "onFastAccess"],
  data: () => ({
    files: [],
    file: undefined,
    fastAccessToggle: true
  }),
  methods: {
    ...mapActions("auth", ["checkToken"]),
    ...mapActions("drive", ["getFastAccess"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    async downloadFileFastAccess(item) {
      // 더블 클릭시, active효과 남아있어야함.
      const index = this.files.findIndex(({ id }) => id == item.id);
      this.file = index;

      if (item.isShortcut) {
        const { status, data } = await showShortcut(item.id);

        if (status != 200) {
          return this.openSnackbar({
            message: i18n.t("drive.4"),
            type: "ERROR"
          });
        }

        if (data.result) {
          window.open(`${data.url}`);
        } else {
          this.openSnackbar({
            message: i18n.t("drive.29"),
            type: "VALIDATION"
          });
        }
      } else {
        await this.checkToken();
        const fileIds = [item.id];
        const token = storage.getAccessToken();
        window.open(
          `${process.env.VUE_APP_API_SERVER_URL}/api/drive/download?fileIds=${fileIds}&access_token=${token}`
        );
      }

      this.file = undefined;
    },

    fileActiveOff() {
      this.file = undefined;
    },
    fastAccessOnOff() {
      this.$emit("update:onFastAccess", !this.onFastAccess);
    }
  }
};
</script>
