<template>
  <v-navigation-drawer
    absolute
    right
    v-show="drawer"
    v-model="drawer"
    width="320"
    class="cr-drive-setting"
  >
    <v-card class="mx-auto" width="320" height="100%">
      <v-card-title class="text-h5" v-text="$t('common.설정')"> </v-card-title>

      <div style="height: calc(100% - 117px); overflow-y: auto;"></div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text plain v-text="$t('drive.13')"> </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    pageSizeList: [
      { text: "4", value: 4 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "30", value: 30 },
      { text: "40", value: 40 },
      { text: "50", value: 50 }
    ]
  }),
  computed: {
    ...mapGetters("driveLayout", ["getShowSetting"]),
    drawer: {
      get() {
        return this.getShowSetting;
      },
      set(drawer) {
        this.SET_SHOW_SETTING(drawer);
      }
    }
  },
  methods: {
    ...mapMutations("driveLayout", ["SET_SHOW_SETTING"])
  }
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer.cr-drive-setting ::v-deep {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 8px 0px 0px;
}
</style>
